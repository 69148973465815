<div
  class="flex h-screen justify-center items-center w-full min-h-screen flex-col"
>
  <div class="text-5xl mb-10 text-purple-100 flex">
    (
    <div class="ml-4 transform rotate-90">:-(</div>
    <span class="animate-bounce">'</span>)
  </div>
  <h1 class="text-9xl mb-5 text-purple-100">404</h1>
  <p class="text-xl text-gray-80 mb-10">
    Oops. The page you're looking for doesn't exist.
  </p>
  <button class="btn btn-primary" [routerLink]="['/']">Go home</button>
</div>
