import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

import { NgModule } from '@angular/core';

import { environment } from '../../../../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.azureAd.stsServer,
        authWellknownEndpointUrl: environment.azureAd.authWellknownEndpoint,
        redirectUrl: environment.azureAd.redirectUrl,
        clientId: environment.azureAd.clientId,
        scope: environment.azureAd.scope,
        responseType: 'code',
        silentRenew: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        issValidationOff: true,
        autoUserInfo: false,
        triggerAuthorizationResultEvent: true,
        // silentRenewUrl: window.location.origin + '/silent-renew.html',
        useRefreshToken: true,
        logLevel: LogLevel.Error,
        customParamsAuthRequest: {
          prompt: 'select_account', // login, consent
        },
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
