import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, filter, mergeMap, take, tap } from 'rxjs/operators';

import { RoleEnum } from '@app/roles';

import { GetProfileData } from '../../state/auth/auth.actions';
import { getUserData } from '../../state/auth/auth.selectors';

import { User } from '../interfaces/user.interface';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.authService.getAuthToken()) {
      return this.getUserDataFromStoreOrApi(state.url).pipe(
        mergeMap((user) => {
          const accessRoles = route.data.roles;
          const { roles: userRoles } = user;

          if (accessRoles) {
            if (Array.isArray(accessRoles)) {
              const hasAccess = userRoles.some((role) =>
                accessRoles.includes(role)
              );

              if (!hasAccess) {
                if (userRoles.includes(RoleEnum.User)) {
                  this.router.navigateByUrl('/');
                }
              }
              return of(true);
            }
          }

          return of(true);
        }),
        catchError(() => of(false))
      );
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return of(false);
  }

  private getUserDataFromStoreOrApi(returnUrl?: string): Observable<User> {
    return this.store.select(getUserData).pipe(
      tap((user: User) => {
        if (!user) {
          this.store.dispatch(GetProfileData({ returnUrl }));
        }
      }),
      filter((user) => user !== null),
      take(1)
    );
  }
}
