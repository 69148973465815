import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LoginResponse, ProfileResponse } from '@app/api-definition';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public constructor(private http: HttpClient) {}

  public login(username: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/auth/login', {
      username,
      password,
    });
  }

  public loginWithOidc(token: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      '/api/auth/login-oidc',
      {},
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  }

  public getAuthToken(): string | null {
    return localStorage.getItem('token') || null;
  }

  public getProfileData(): Observable<ProfileResponse> {
    return this.http.get<ProfileResponse>('/api/users/profile');
  }
}
