import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OutOfDateService } from '../../gt-home/services/out-of-date.service';

@Injectable()
export class OutOfDateInterceptor implements HttpInterceptor {
  public constructor(private outOfDateService: OutOfDateService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          this.outOfDateService.modalState = true;
          this.outOfDateService.message = error?.error?.message;
        }
        return throwError(error);
      })
    );
  }
}
