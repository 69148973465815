import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../../core/services/auth.service';
import {
  AuthActionTypes,
  GetProfileData,
  GetProfileDataFailure,
  LoginFailure,
  LoginSuccess,
  UpdateProfileData,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  private login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      switchMap(({ email, password, returnUrl }) =>
        this.authService.login(email, password).pipe(
          map(({ data: { accessToken: token } }) =>
            LoginSuccess({ token, returnUrl })
          ),
          catchError(({ error }) => {
            const errorMessage = Array.isArray(error?.message)
              ? error?.message[0]
              : error?.message;
            return of(LoginFailure({ error: errorMessage }));
          })
        )
      )
    )
  );

  private loginWithOidc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN_WITH_OIDC),
      switchMap(({ token }) =>
        this.authService.loginWithOidc(token).pipe(
          map(({ data: { accessToken: apiToken } }) =>
            LoginSuccess({ token: apiToken })
          ),
          catchError(({ error }) => {
            return of(LoginFailure({ error: error?.message }));
          })
        )
      )
    )
  );

  private loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN_SUCCESS),
      tap(({ token }) => {
        localStorage.setItem('token', token);
      }),
      switchMap(({ returnUrl }) => of(GetProfileData({ returnUrl })))
    )
  );

  private logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => {
          localStorage.removeItem('token');
          this.router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  private getProfileData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.GET_PROFILE_DATA),
      switchMap(({ returnUrl }) =>
        this.authService.getProfileData().pipe(
          map(({ data: { id, email, roles, teams } }) =>
            UpdateProfileData({ user: { id, email, roles, teams }, returnUrl })
          ),
          catchError(() => of(GetProfileDataFailure()))
        )
      )
    )
  );

  private getProfileDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.GET_PROFILE_DATA_FAILURE),
        tap(() => {
          localStorage.removeItem('token');
        })
      ),
    { dispatch: false }
  );

  private redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.UPDATE_PROFILE_DATA),
        tap(({ returnUrl }) => {
          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
          } else {
            this.router.navigateByUrl('/');
          }
        })
      ),
    { dispatch: false }
  );

  public constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}
}
