export const environment = {
  production: false,
  enableDirectSignIn: true,
  apiUrl: 'https://api.taxflow-dev.yenko.io',
  azureAd: {
    stsServer:
      'https://login.microsoftonline.com/0c9b881a-694f-4878-8a39-2a297ccc8f81/v2.0',
    authWellknownEndpoint:
      'https://login.microsoftonline.com/0c9b881a-694f-4878-8a39-2a297ccc8f81/v2.0',
    redirectUrl: 'https://taxflow-dev.yenko.io/login/oidc',
    clientId: '079aec95-7e87-4be2-9ffa-33a894e37e4c',
    scope:
      'openid profile offline_access email api://079aec95-7e87-4be2-9ffa-33a894e37e4c/gt-web-app User.ReadWrite Team.ReadBasic.All Channel.ReadBasic.All Files.Read.All Files.ReadWrite.All',
  },
  enableChristmas: false,
};
