import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './core/pages/not-found/not-found.component';

import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/gt-home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['user'],
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./modules/gt-admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./modules/gt-login/login.module').then((m) => m.LoginModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
