/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OutOfDateService {
  private isOutOfDateModalOpen: boolean;
  private outOfDateErrorMessage: string;

  private DEFAULT_MESSAGE =
    'Failed to update due to version mismatch. Probably someone else is working in the same place, please refresh the page.';

  public get modalState(): boolean {
    return this.isOutOfDateModalOpen;
  }

  public set modalState(state: boolean) {
    this.isOutOfDateModalOpen = state;
  }

  public get message(): string {
    return this.outOfDateErrorMessage || this.DEFAULT_MESSAGE;
  }

  public set message(message: string) {
    this.outOfDateErrorMessage = message;
  }
}
/* eslint-enable @typescript-eslint/member-ordering */
