import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AUTH_FEATURE_KEY, State } from './auth.reducer';

export const getAuthState = createFeatureSelector<State>(AUTH_FEATURE_KEY);

export const getUserData = createSelector(
  getAuthState,
  (state: State) => state.user
);

export const getUserId = createSelector(getAuthState, (state: State) =>
  state.user ? state.user.id : null
);

export const getUserTeamAccess = createSelector(getAuthState, (state: State) =>
  state.user ? state.user.teams : null
);

export const getUserRoles = createSelector(
  getAuthState,
  (state: State) => state.user?.roles
);

export const getUserEmail = createSelector(
  getAuthState,
  (state: State) => state.user?.email
);

export const getLoadingState = createSelector(
  getAuthState,
  (state: State) => state.isLoading
);

export const getErrorState = createSelector(
  getAuthState,
  (state: State) => state.error
);

export const getAuthorizationState = createSelector(
  getAuthState,
  (state: State) => state.isAuthenticated
);
