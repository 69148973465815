import { Action, createReducer, on } from '@ngrx/store';

import { UserProfile } from '@app/api-definition';

import {
  GetProfileData,
  GetProfileDataFailure,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout,
  UpdateProfileData,
} from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface State {
  isAuthenticated: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  user: UserProfile | null;
  error?: string | null;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: State;
}

export const initialState: State = {
  isAuthenticated: false,
  user: null,
  isLoaded: false,
  isLoading: false,
};

const authReducer = createReducer(
  initialState,
  on(Login, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: true,
    isAuthenticated: false,
    error: null,
  })),
  on(LoginSuccess, (state) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    isAuthenticated: true,
  })),
  on(LoginFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
    isLoaded: true,
    error: error,
  })),
  on(Logout, (state) => ({
    ...state,
    isLoaded: true,
    isAuthenticated: false,
    user: null,
  })),
  on(GetProfileData, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(UpdateProfileData, (state, { user }) => ({
    ...state,
    user,
    isLoading: false,
    isLoaded: true,
  })),
  on(GetProfileDataFailure, (state) => ({
    ...state,
    user: null,
    isLoading: false,
    isLoaded: true,
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}
