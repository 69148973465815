import { createAction, props } from '@ngrx/store';

import { UserProfile } from '@app/api-definition';

import { UserLoginForm } from '../../core/interfaces/user-login-form.interface';
import { UserLoginWithOidc } from '../../core/interfaces/user-login-with-oidc.interface';

export enum AuthActionTypes {
  LOGIN = '[AUTH] Login',
  LOGIN_WITH_OIDC = '[AUTH] Login with OIDC',
  LOGIN_SUCCESS = '[AUTH] Login Success',
  LOGIN_FAILURE = '[AUTH] Login Failure',
  LOGOUT = '[AUTH] Logout',
  GET_PROFILE_DATA = '[AUTH] Get Profile Data',
  UPDATE_PROFILE_DATA = '[AUTH] Update Profile Data',
  REDIRECT = '[AUTH] Redirect',
  GET_PROFILE_DATA_FAILURE = '[AUTH] Get Profile Data Failure',
}

export const Login = createAction(
  AuthActionTypes.LOGIN,
  props<UserLoginForm>()
);

export const LoginWithOidc = createAction(
  AuthActionTypes.LOGIN_WITH_OIDC,
  props<UserLoginWithOidc>()
);

export const LoginSuccess = createAction(
  AuthActionTypes.LOGIN_SUCCESS,
  props<{ token: string; returnUrl?: string }>()
);

export const LoginFailure = createAction(
  AuthActionTypes.LOGIN_FAILURE,
  props<{ error: string }>()
);

export const Logout = createAction(AuthActionTypes.LOGOUT);

export const GetProfileData = createAction(
  AuthActionTypes.GET_PROFILE_DATA,
  props<{ returnUrl?: string }>()
);

export const UpdateProfileData = createAction(
  AuthActionTypes.UPDATE_PROFILE_DATA,
  props<{ user: UserProfile; returnUrl?: string }>()
);

export const GetProfileDataFailure = createAction(
  AuthActionTypes.GET_PROFILE_DATA_FAILURE
);
